/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.osano-cm-powered-by,
.osano-cm-widget,
li.osano-cm-drawer-item:nth-child(5) {
    @apply hidden;
}

.container-x-padding {
  @apply px-4 bg-lcl-lime-40 sm:px-6 lg:px-8 xl:px-12;
}

.container-y-padding {
  @apply  py-4 bg-lcl-lime-40 sm:py-6 lg:py-8 xl:py-12;
}
.container-y-padding {
  @apply  py-4 bg-lcl-lime-40 sm:py-6 lg:py-8 xl:py-12;
}
.y-spacing {
  @apply  space-y-4 bg-lcl-lime-40 sm:space-y-6 lg:space-y-8 xl:space-y-12;
}

.site-x-padding {
    @apply
        w-full px-4 sm:px-8 md:px-16 lg:px-24;
}
.site-container {
    @apply
        w-full max-w-screen-xl mx-auto;
}
.h-navigation {
    @apply h-16 md:h-24
}

/* Clears the nav bar for all anchor links */
[id]::before {
    @apply 
        content-['']
        relative
        block
        h-20
        md:h-28
        -mt-20
        md:-mt-28
        z-0
        invisible
  }


.p-sm {
    @apply
        p-4 sm:p-6 md:p-8;
}
.p-md {
    @apply
        p-6 sm:p-8 md:p-10;
}
.p-lg {
    @apply
        p-8 sm:p-10 md:p-14;
}

.py-sm {
    @apply
        py-4 sm:py-6 md:py-8;
}
.py-md {
    @apply
        py-6 sm:py-8 md:py-10;
}
.py-lg {
    @apply
        py-8 sm:py-10 md:py-14;
}

.gap-xs {
    @apply
        gap-1;
}

.gap-sm {
    @apply
        gap-2;
}
.gap-md {
    @apply
        gap-6;
}
.gap-lg {
    @apply
        gap-12;
}

.space-y-sm {
    @apply
        space-y-4
}
.space-y-md {
    @apply
        space-y-6
}
.space-y-lg {
    @apply
        space-y-12
}

.space-y-section {
    @apply
        space-y-16
}


/* Create font tokens to simplify typography / make it more consistent */

.font-setup {
    @apply
        relative antialiased;
}

.token-h1,
.token-h2,
.token-h3,
.token-h4,
.token-h5,
.token-h6 {
    @apply
        font-setup;
}

.token-h1 {
    @apply
        -tracking-[0.03rem]
        text-[2rem]
        sm:text-[2.75rem]
        md:text-[3rem]
        lg:text-[3.5rem]
        leading-[105%];
}
.token-h2 {
    @apply
        text-[1.75rem]
        sm:text-[2rem]
        md:text-[2.5rem]
        leading-[110.5%];
}
.token-h3 {
    @apply
        font-light
        text-[1.5rem]
        sm:text-[1.75rem]
        md:text-[2rem]
        leading-[110.5%];
}
.token-h4 {
    @apply
        font-medium
        -tracking-[0.02rem]
        leading-[110.5%]
        text-[1.25rem]
        sm:text-[1.5rem]
        md:text-[1.625rem];
}
.token-h5 {
    @apply
        font-medium
        leading-[128%]
        text-[1rem]
        sm:text-[1.125rem]
        md:text-[1.25rem];
}

.token-pxl,
.token-p,
.token-psm,
.token-pxs,
.token-label,
.token-caption,
.token-slug {
    @apply
        font-setup
        leading-[140%];
}

.token-pxl {
    @apply
        -tracking-[0.01rem]
        text-[0.875rem]
        sm:text-[1.000rem]
        md:text-[1.25rem];
}
.token-p {
    @apply
        text-[0.875rem]
        sm:text-[0.925rem]
        md:text-[1.000rem]
}

.token-psm {
    @apply
        text-[0.75rem]
        sm:text-[0.875rem]
        md:text-[0.925rem];
}
.token-pxs {
    @apply
        text-[0.5rem]
        sm:text-[0.625rem]
        md:text-[0.75rem];
}

.token-label {
    @apply
        font-medium tracking-wider uppercase token-pxs;
}
.token-slug {
    @apply
        font-light tracking-wider uppercase token-psm;
}
.token-slug-small {
    @apply
        tracking-wider uppercase token-pxs;
}

.rounded-default {
    @apply 
        rounded-lg
        md:rounded-xl
        lg:rounded-2xl
}

/* Reset prose to match the styles */

.prose {
    h1 {
        @apply
            text-lcl-black-100
            token-h1
            font-light
    }
    h2 {
        @apply
            text-lcl-black-100
            token-h3
            font-medium
    }
    h3 {
        @apply
            text-lcl-black-100
            token-h4
            font-light
    }
    h4 {
        @apply
            text-lcl-black-55
            token-h5
            font-medium
    }
    h5 {
        @apply
            text-lcl-black-100
            token-p
            font-medium
    }
    p, ul, ol {
        @apply
            token-p;
    }
    ul, ol {
        @apply
            marker:text-lcl-black-100;
    }
    a {
        @apply 
            relative font-normal duration-700 text-lcl-blue-70 z-20;
    }
}

/* Let's create consistent button types */

.button {
    @apply
        flex self-start px-4 py-2 transition-colors duration-700 rounded-lg token-slug w-fit whitespace-nowrap;

    &.primary {
        @apply
            text-white bg-lcl-blue-70 hover:bg-lcl-blue-85;
    }
    &.secondary {
        @apply
            text-lcl-black-100
            bg-lcl-lime-40
            hover:bg-lcl-lime-55
    }
    &.ghost {
        @apply
            border border-lcl-black-100 text-lcl-black-100 hover:bg-lcl-lime-55 hover:border-lcl-lime-55;
    }
    &.ghost_reverse {
        @apply
            text-white border border-white hover:bg-lcl-lime-70 hover:border-lcl-lime-70;
    }
    &.alert {
        @apply
            font-bold border border-lcl-black-100 text-lcl-black-100 token-slug-small;
    }
    &.pill {
        @apply
            px-4 py-3 font-light tracking-tight normal-case border rounded-full token-p;
        &.small {
            @apply
                px-3 py-1 token-psm
        }
    }

}




.form-label {
  @apply block mb-2 token-label;
}
.form-option {
  @apply block mt-1 token-psm;
}

.form-input,
.form-textarea,
.form-select,
.form-multiselect,
.form-checkbox,
.form-radio {
  @apply border-lcl-black-25 token-p focus:ring-lcl-lime-55;
}

.form-input,
.form-textarea,
.form-select,
.form-multiselect,
.form-checkbox {
  @apply rounded-md;
}
.form-input,
.form-textarea,
.form-select,
.form-multiselect {
  @apply block w-full border;
}

.form-checkbox-group,
.form-radio-group {
  @apply flex items-center break-inside-avoid;
}

.form-checkbox,
.form-radio {
  @apply w-4 h-4 mt-1.5 mr-3 text-lcl-lime-55;
}

.form-checkbox-group .form-label,
.form-radio-group .form-label {
  @apply mb-0;
}

.form-btn {
  @apply block w-full px-8 py-4 text-lg tracking-wider uppercase rounded-lg md:w-fit bg-lcl-lime-40;
}
